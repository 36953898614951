import { getAuth, getAuthAssetPanel } from '@/api/auth.api';
import { reactive } from 'vue';
import type { Authentication, QueryParams, Settings, SettingsPayload, State } from './types';
import loading from '@/store/loading';
import type { AxiosError, AxiosResponse } from 'axios';
import { getSettings, updateSettings } from '@/api/settings.api';
import { getGroups, getUser } from '@/api/users.api';
import { useJWT } from '#auth/composables/useJWT';

const { setToken, removeToken } = useJWT();

// state
const state: State = reactive({
  authentication: {
    auth_token: '',
    base_url: '',
    is_licensed: true,
    should_allow_access: true,
    should_use_new_onboarding: false,
  },
  queryParams: {
    xdm_e: '',
    xdm_c: '',
    cp: '',
    lic: '',
    cv: '',
    jwt: '',
  },
  isLoading: false,
  atlassianUsername: '',
  atlassianId: '',
  currentSettings: {
    id: '',
    should_allow_admins_only: false,
    should_auto_link_assets: false,
    groups_allowed_to_access: [],
    groups_with_read_access: [],
    labels: null,
    api_key: '',
    should_restrict_projects: false,
    projects_with_access: [],
  },
  currentUser: {},
  allGroups: [],
});

// methods
const methods = {
  setQueryParams(queryParams: QueryParams): void {
    state.queryParams = queryParams;
  },
  authenticate(): void {
    state.isLoading = true;
    // @ts-expect-error This comes from the Atlassian connect library
    if (typeof AP !== 'undefined' && AP && typeof AP.request === 'function') {
      // @ts-expect-error This comes from the Atlassian connect library
      AP.request('/rest/api/3/myself?expand=groups').then((data) => {
        const user = JSON.parse(data.body);
        state.currentUser = user;
        if (user && user.displayName) {
          state.atlassianId = user.accountId;
          state.atlassianUsername = user.displayName;
        }

        let queryParams = state.queryParams;
        if (state.atlassianId !== '') {
          queryParams = {
            ...{ atlassianAccountId: state.atlassianId },
            ...queryParams,
          };
        }

        removeToken();

        getAuth(queryParams)
          .then((response: AxiosResponse<Authentication>) => {
            state.authentication = response.data;
            setToken(response.data.auth_token);
            state.isLoading = false;
            this.getUserGroups();
            this.getCurrentSettings();
          })
          .catch((error: AxiosError) => {
            let errorMessage = '';
            switch (error.response?.status) {
              case 400:
              case 401:
                errorMessage = error.response.data.message;
                break;

              default:
                errorMessage =
                  'We are having some trouble reaching our servers. Please reach out to support@assetmanagementforjira.com for help';
            }
            state.isLoading = false;
            loading.methods.setError(true, errorMessage);
            throw error;
          });
      });

      // // @ts-expect-error This comes from the Atlassian connect library
      // AP.request("/rest/api/3/groups/picker?query=").then((data) => {
      //   state.allGroups = JSON.parse(data.body).groups;
      // });

      // this.getCurrentSettings();
    }
  },
  authenticateAssetPanel(): void {
    state.isLoading = true;

    // @ts-expect-error This comes from the Atlassian connect library
    if (typeof AP !== 'undefined' && AP && typeof AP.request === 'function') {
      // @ts-expect-error This comes from the Atlassian connect library
      AP.request('/rest/api/3/myself').then((data) => {
        const user = JSON.parse(data.body);
        state.currentUser = user;
        if (user && user.displayName) {
          state.atlassianId = user.accountId;
          state.atlassianUsername = user.displayName;
        }

        let queryParams = state.queryParams;
        if (state.atlassianId !== '') {
          queryParams = {
            ...{ atlassianAccountId: state.atlassianId },
            ...queryParams,
          };
        }

        removeToken();

        getAuthAssetPanel(queryParams)
          .then((response: AxiosResponse<Authentication>) => {
            state.authentication = response.data;

            setToken(response.data.auth_token);
            state.isLoading = false;
          })
          .catch((error: AxiosError) => {
            let errorMessage = '';
            switch (error.response?.status) {
              case 400:
              case 401:
                errorMessage = error.response.data.message;
                break;

              default:
                errorMessage =
                  'We are having some trouble reaching our servers. Please reach out to support@assetmanagementforjira.com for help';
            }
            state.isLoading = false;
            loading.methods.setError(true, errorMessage);
            throw error;
          });
      });
    }
  },
  getUserGroups(): void {
    if (state.atlassianId !== '') {
      getUser(state.atlassianId).then((response: AxiosResponse) => {
        state.currentUser.groups = response.data.groups;
      });
    }
  },
  getAllGroups(): void {
    getGroups().then((response: AxiosResponse) => {
      state.allGroups = response.data.groups;
    });
  },
  async getCurrentSettings(): Promise<void> {
    await getSettings().then((response: AxiosResponse<Settings>) => {
      state.currentSettings = response.data;

      if (
        state.currentSettings.groups_allowed_to_access === undefined ||
        state.currentSettings.groups_allowed_to_access === null
      ) {
        state.currentSettings.groups_allowed_to_access = [this.getGroupIdForSiteAdmin()];
      }

      if (
        state.currentSettings.groups_with_read_access === undefined ||
        state.currentSettings.groups_with_read_access === null
      ) {
        state.currentSettings.groups_with_read_access = [];
      }
    });
  },
  updateSettings(settings: Settings): void {
    const payload: SettingsPayload = {
      should_allow_admins_only: settings.should_allow_admins_only,
      should_auto_link_assets: settings.should_auto_link_assets,
      groups_allowed_to_access: settings.groups_allowed_to_access,
      groups_with_read_access: settings.groups_with_read_access,
      labels: JSON.stringify(settings.labels),
      should_restrict_projects: settings.should_restrict_projects,
      projects_with_access: settings.projects_with_access,
    };
    updateSettings(state.currentSettings.id, payload).then(() => {
      this.getCurrentSettings();
    });
  },
  isUserSiteAdmin(): boolean {
    let isAdmin = false;
    try {
      // @ts-expect-error This comes from the Atlassian connect library
      state.currentUser.groups.items.forEach((group) => {
        if (group.name === 'site-admins' || group.name === 'org-admins') {
          isAdmin = true;
        }
      });
    } catch (e) {
      isAdmin = false;
    }

    return isAdmin;
  },
  getGroupIdForSiteAdmin(): string {
    let groupId = '';
    state.allGroups.forEach((group) => {
      if (group.name === 'site-admins' || group.name === 'org-admins') {
        groupId = group.groupId;
      }
    });

    return groupId;
  },
  // check if the current user is allowed to access the App
  shouldAllowAccess(): boolean {
    if (state.currentSettings.should_allow_admins_only === false) {
      return true;
    }

    if (this.isUserSiteAdmin()) {
      return true;
    }

    let shouldAllow = false;

    const userGroups: Array<string> = [];
    // @ts-expect-error This comes from the Atlassian connect library
    state.currentUser.groups.items.forEach((group) => {
      userGroups.push(group.groupId);
    });

    state.currentSettings.groups_allowed_to_access.forEach((group) => {
      if (userGroups.includes(group)) {
        shouldAllow = true;
        return;
      }
    });

    state.currentSettings.groups_with_read_access.forEach((group) => {
      if (userGroups.includes(group)) {
        shouldAllow = true;
        return;
      }
    });

    return shouldAllow;
  },
  // check whether current user should get full access (read & write) access
  shouldAllowFullAccess(): boolean {
    if (state.currentSettings.should_allow_admins_only === false) {
      return true;
    }

    if (this.isUserSiteAdmin()) {
      return true;
    }

    let shouldAllow = false;

    const userGroups: Array<string> = [];
    // @ts-expect-error This comes from the Atlassian connect library
    state.currentUser.groups.items.forEach((group) => {
      userGroups.push(group.groupId);
    });

    state.currentSettings.groups_allowed_to_access.forEach((group) => {
      if (userGroups.includes(group)) {
        shouldAllow = true;
        return;
      }
    });

    return shouldAllow;
  },
};

export default {
  state,
  methods,
};
