import { ref } from 'vue';

const TOKEN_KEY = 'authToken';

const jwtToken = ref('');

export const useJWT = () => {
  const setToken = (token: string) => {
    jwtToken.value = token;
    localStorage.setItem(TOKEN_KEY, token);
  };

  const getToken = () => {
    const token = jwtToken.value || localStorage.getItem(TOKEN_KEY);

    return `JWT ${token}`;
  };

  const removeToken = () => {
    jwtToken.value = '';
    localStorage.removeItem(TOKEN_KEY);
  };

  return { setToken, getToken, removeToken };
};
