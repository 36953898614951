<script setup lang="ts">
import { provide } from 'vue';
import { RouterView } from 'vue-router';
import store from '@/store';
import { AtlasAppProvider } from '#atlaskit';
import { AppWrapper, FlagsContainer } from '#common';
import AssetPanelBenefits from '#onboarding/components/AssetPanelBenefits.vue';

provide('store', store);
</script>

<template>
  <AtlasAppProvider>
    <AssetPanelBenefits />

    <RouterView v-slot="{ Component, route }">
      <AppWrapper
        v-if="route.meta.appWrapper"
        :contained="!!route.meta.contained"
        :sidebar="!route.meta.noSidebar"
      >
        <component
          :is="Component"
          :key="`${route.path}-component`"
        />
      </AppWrapper>
      <component
        :is="Component"
        v-else
        :key="`${route.path}-component`"
      />
    </RouterView>

    <FlagsContainer />
  </AtlasAppProvider>
</template>

<style>
#ac-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
