export enum QueryKey {
  REPORTS = 'reports',
  REPORT = 'report',
}

export enum RouteName {
  HOME = 'reports-home',
  NEW = 'reports-new',
  EDIT = 'reports-edit',
}

export enum BaseAssetField {
  ASSET_TYPE = 'Asset Type',
  LOCATION = 'Location',
  LOANEE = 'Loanee',
}

export const EMPTY_FILTER_VALUE = '__empty__';

export enum TextOperator {
  CONTAINS = 'CONTAINS',
}

export enum SelectOperator {
  IS = 'IS',
  IS_NOT = 'IS NOT',
}

export enum NumberOperator {
  LESS = 'IS LESS THAN',
  EQUAL = 'IS EQUAL TO',
  GREATER = 'IS GREATER THAN',
}

export enum DateOperator {
  NEXT_DAYS = 'IS IN THE NEXT __ DAYS',
  PAST_DAYS = 'WAS IN THE LAST __ DAYS',
  BETWEEN = 'IS BETWEEN',
}
