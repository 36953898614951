import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/loanees',
    name: RouteName.HOME,
    component: () => import('./views/HomeView'),
    meta: { atlaskit: true, appWrapper: true },
  },
  {
    path: '/loanees/new',
    name: RouteName.CREATE,
    component: () => import('./views/CreateUserView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/loanees/:id',
    name: RouteName.DETAILS,
    component: () => import('./views/DetailsUserView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
  {
    path: '/loanees/:id/edit',
    name: RouteName.EDIT,
    component: () => import('./views/EditUserView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
];
