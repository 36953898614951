export enum RouteName {
  HOME = 'accessories-home',
  CREATE = 'accessories-create',
  EDIT = 'accessories-edit',
  FIELDS = 'accessories-fields',
}

export enum QueryKey {
  ACCESSORIES = 'accessories',
  ACCESSORY = 'accessory',
  FIELDS = 'accessories-fields',
  FIELD = 'accessories-field',
}
